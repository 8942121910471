.ma-0
  margin: 0 !important

.ma-1
  margin: 4px !important

.ma-2
  margin: 8px !important

.ma-3
  margin: 12px !important

.ma-4
  margin: 16px !important

.ma-5
  margin: 20px !important

.ma-6
  margin: 24px !important

.ma-7
  margin: 28px !important

.ma-8
  margin: 32px !important

.ma-9
  margin: 36px !important

.ma-10
  margin: 40px !important

.ma-11
  margin: 44px !important

.ma-12
  margin: 48px !important

.ma-13
  margin: 52px !important

.ma-14
  margin: 56px !important

.ma-15
  margin: 60px !important

.ma-16
  margin: 64px !important

.ma-auto
  margin: auto !important

.mx-0
  margin-right: 0 !important
  margin-left: 0 !important

.mx-1
  margin-right: 4px !important
  margin-left: 4px !important

.mx-2
  margin-right: 8px !important
  margin-left: 8px !important

.mx-3
  margin-right: 12px !important
  margin-left: 12px !important

.mx-4
  margin-right: 16px !important
  margin-left: 16px !important

.mx-5
  margin-right: 20px !important
  margin-left: 20px !important

.mx-6
  margin-right: 24px !important
  margin-left: 24px !important

.mx-7
  margin-right: 28px !important
  margin-left: 28px !important

.mx-8
  margin-right: 32px !important
  margin-left: 32px !important

.mx-9
  margin-right: 36px !important
  margin-left: 36px !important

.mx-10
  margin-right: 40px !important
  margin-left: 40px !important

.mx-11
  margin-right: 44px !important
  margin-left: 44px !important

.mx-12
  margin-right: 48px !important
  margin-left: 48px !important

.mx-13
  margin-right: 52px !important
  margin-left: 52px !important

.mx-14
  margin-right: 56px !important
  margin-left: 56px !important

.mx-15
  margin-right: 60px !important
  margin-left: 60px !important

.mx-16
  margin-right: 64px !important
  margin-left: 64px !important

.mx-auto
  margin-right: auto !important
  margin-left: auto !important

.my-0
  margin-top: 0 !important
  margin-bottom: 0 !important

.my-1
  margin-top: 4px !important
  margin-bottom: 4px !important

.my-2
  margin-top: 8px !important
  margin-bottom: 8px !important

.my-3
  margin-top: 12px !important
  margin-bottom: 12px !important

.my-4
  margin-top: 16px !important
  margin-bottom: 16px !important

.my-5
  margin-top: 20px !important
  margin-bottom: 20px !important

.my-6
  margin-top: 24px !important
  margin-bottom: 24px !important

.my-7
  margin-top: 28px !important
  margin-bottom: 28px !important

.my-8
  margin-top: 32px !important
  margin-bottom: 32px !important

.my-9
  margin-top: 36px !important
  margin-bottom: 36px !important

.my-10
  margin-top: 40px !important
  margin-bottom: 40px !important

.my-11
  margin-top: 44px !important
  margin-bottom: 44px !important

.my-12
  margin-top: 48px !important
  margin-bottom: 48px !important

.my-13
  margin-top: 52px !important
  margin-bottom: 52px !important

.my-14
  margin-top: 56px !important
  margin-bottom: 56px !important

.my-15
  margin-top: 60px !important
  margin-bottom: 60px !important

.my-16
  margin-top: 64px !important
  margin-bottom: 64px !important

.my-auto
  margin-top: auto !important
  margin-bottom: auto !important

.mt-0
  margin-top: 0 !important

.mt-1
  margin-top: 4px !important

.mt-2
  margin-top: 8px !important

.mt-3
  margin-top: 12px !important

.mt-4
  margin-top: 16px !important

.mt-4-zi-0
  z-index: 0 !important
  margin-top: 16px !important

.mt-5
  margin-top: 20px !important

.mt-6
  margin-top: 24px !important

.mt-7
  margin-top: 28px !important

.mt-8
  margin-top: 32px !important

.mt-9
  margin-top: 36px !important

.mt-10
  margin-top: 40px !important

.mt-11
  margin-top: 44px !important

.mt-12
  margin-top: 48px !important

.mt-13
  margin-top: 52px !important

.mt-14
  margin-top: 56px !important

.mt-15
  margin-top: 60px !important

.mt-16
  margin-top: 64px !important

.mt-auto
  margin-top: auto !important

.mr-0
  margin-right: 0 !important

.mr-1
  margin-right: 4px !important

.mr-2
  margin-right: 8px !important

.mr-3
  margin-right: 12px !important

.mr-4
  margin-right: 16px !important

.mr-5
  margin-right: 20px !important

.mr-6
  margin-right: 24px !important

.mr-7
  margin-right: 28px !important

.mr-8
  margin-right: 32px !important

.mr-9
  margin-right: 36px !important

.mr-10
  margin-right: 40px !important

.mr-11
  margin-right: 44px !important

.mr-12
  margin-right: 48px !important

.mr-13
  margin-right: 52px !important

.mr-14
  margin-right: 56px !important

.mr-15
  margin-right: 60px !important

.mr-16
  margin-right: 64px !important

.mr-auto
  margin-right: auto !important

.mb-0
  margin-bottom: 0 !important

.mb-1
  margin-bottom: 4px !important

.mb-2
  margin-bottom: 8px !important

.mb-3
  margin-bottom: 12px !important

.mb-4
  margin-bottom: 16px !important

.mb-5
  margin-bottom: 20px !important

.mb-6
  margin-bottom: 24px !important

.mb-7
  margin-bottom: 28px !important

.mb-8
  margin-bottom: 32px !important

.mb-9
  margin-bottom: 36px !important

.mb-10
  margin-bottom: 40px !important

.mb-11
  margin-bottom: 44px !important

.mb-12
  margin-bottom: 48px !important

.mb-13
  margin-bottom: 52px !important

.mb-14
  margin-bottom: 56px !important

.mb-15
  margin-bottom: 60px !important

.mb-16
  margin-bottom: 64px !important

.mb-auto
  margin-bottom: auto !important

.ml-0
  margin-left: 0 !important

.ml-1
  margin-left: 4px !important

.ml-2
  margin-left: 8px !important

.ml-3
  margin-left: 12px !important

.ml-4
  margin-left: 16px !important

.ml-5
  margin-left: 20px !important

.ml-6
  margin-left: 24px !important

.ml-7
  margin-left: 28px !important

.ml-8
  margin-left: 32px !important

.ml-9
  margin-left: 36px !important

.ml-10
  margin-left: 40px !important

.ml-11
  margin-left: 44px !important

.ml-12
  margin-left: 48px !important

.ml-13
  margin-left: 52px !important

.ml-14
  margin-left: 56px !important

.ml-15
  margin-left: 60px !important

.ml-16
  margin-left: 64px !important

.ml-auto
  margin-left: auto !important
