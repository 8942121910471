// new UI
// OLD: tags.sass

.tag
  display: inline-block
  border: 1px solid #a6a6a6
  border-radius: 4px
  color: #575757
  white-space: nowrap
  line-height: 20px
  font-size: 15px
  padding: 0 5px
  &--clickable
    cursor: pointer
    &:hover
      background-color: #a6a6a6
      color: white
    &.tag--highlight
      &:hover
        background-color: #3095dd

  &--highlight
    background-color: #498ce9
    border-color: #498ce9
    color: #fff
    font-weight: normal

  &--small
    font-size: 10px

  &--withMarginLeft
    margin-left: 10px

  &--main
    border-color: #DA70D6FF
    border-width: 2px

  &:hover &__delete
    display: block
  &__delete
    cursor: pointer
    display: none
    position: absolute
    right: 0
    top: 0
    transform: translate(50%, -50%)
    width: 18px
    height: 18px
    border: 1px solid #333
    border-radius: 50%
    color: #333
    background-color: white
    text-align: center
    line-height: 14px
    font-size: 14px
