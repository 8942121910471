.scroll-to-bottom
  margin-right: 40px
  bottom: 40px
  position: -webkit-sticky
  position: sticky
  margin-left: auto
  background: #f6f6f6
  width: 48px
  height: 48px
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  height: 0

  &:after
    content: ''
    background: #f6f6f6
    width: 48px
    height: 48px
    position: absolute
    z-index: -1
    border-radius: 50%
    transform: translate(0%, -50%)
    left: 0
    top: 0

  .material-icons
    font-size: 32px
