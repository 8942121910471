@import '../variables/colors.sass'

.dropdown
  position: absolute
  bottom: -5px
  transform: translateY(100%)
  border-radius: 4px
  min-width: 150px
  max-height: 350px
  overflow-y: auto
  padding: 5px 0
  background-color: white
  box-shadow: 0 0 8px 1px rgba(31, 28, 22, 0.14)
  z-index: 1000

  &--left
    left: 0
  &--right
    right: 0
  &__header
    font-size: 120%
    font-weight: bold
    padding-left: 5px
  &__item
    cursor: pointer
    padding: 10px 25px
    white-space: nowrap
    color: $color-emphasis

    &:hover
      background-color: #fcfcfc

  &__hr
    height: 1px
    background-color: #eee

  &__overlay
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0

    z-index: 999 // на 1 меньше, чем у дропдауна
