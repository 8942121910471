// new UI
// OLD: tags.sass

.tag-list
  display: inline-block
  vertical-align: middle
  margin-top: -10px
  margin-right: -5px

  &__add
    margin-left: 5px
    cursor: pointer
    padding: 0 10px
    border: 1px solid #3095dd
    color: #3095dd

    &:hover
      background-color: #3095dd
      color: white

    &:active
      opacity: 0.6

  & .tag
    position: relative
    margin-top: 10px
    margin-right: 5px

.category-list
  @extend .tag-list
