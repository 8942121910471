.ticket-category
  margin-right: 5px
  background-color: #f2f2f2
  padding: 2px 4px
  border-radius: 4px
  white-space: nowrap
  display: inline-block

.select-control-custom
  width: calc(100% - 40px)
  min-width: 230px
  margin-right: 20px

.button
  padding: 6px 12px

.categories-instruction-text
  text-align: center
  font-size: 12px

.ticket-header
  display: flex
  align-items: center

.auto-categories-container
  display: flex
  flex-direction: column
  margin-top: 5px

.ticket-categories, .auto-categories-display, .auto-categories-controls-row
  font-size: 12px

.auto-categories-display, .auto-categories-controls-row
  font-size: 12px
  margin-top: 5px
  margin-right: 5px

.categories-instruction-text, .primary-category-select, .sub-category-select, .button
  margin-right: 5px

.select-control-custom, .save-auto-categories-button.button
  margin-right: 20px

.save-auto-categories-button.button
  padding: 6px 12px

.auto-categories-row
  display: flex
  align-items: center

.selects-container
  display: flex
  flex-grow: 1

.auto-categories-controls-row
  display: flex
  justify-content: center
  align-items: center

.separate
  margin-bottom: 10px
  margin-top: 10px

.ticket-categories-form
  display: flex
  flex-direction: column
  margin-top: 5px

  &__row
    display: flex
    align-items: center
    justify-content: center
    margin-top: 5px
    margin-bottom: 5px
    gap: 10px

  &__label
    flex: 1
    font-size: 12px

  &__select
    font-size: 12px
    flex: 1

  &__button
    display: flex
    flex: 0.5
    justify-content: end
