@import '../variables/colors.sass'

.notice
  display: flex
  align-items: center
  padding: 10px
  border-radius: 4px
  transform: scale(1.05)
  opacity: 0.8
  transition: transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), opacity 0.2s

  &--animate
    transform: scale(1)
    opacity: 1

  &__icon
    margin-right: 10px

  &--warning
    background-color: $color-yellow-dim
  &--error
    background-color: #fce1e1
  &--error &__text
    color: #ea3838
  &--error &__icon
    color: #ef6a6a

  &--done
    background-color: #d0f7d5
  &--done &__text
    color: green
  &--done &__icon
    color: green
