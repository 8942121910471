.partner-select
  &__img-container
    margin-right: 20px
    display: inline-block
    height: 50px
    width: 50px

    & > img
      max-width: 100%
      max-height: 100%

  &__main-info
    vertical-align: top
    width: 360px
    display: inline-block

  &__title
    font-weight: bold
  &__city
    font-size: 80%

  &__additional-info
    vertical-align: top
    display: inline-block
    font-size: 90%
    font-weight: bold
