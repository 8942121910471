.icon
  &.material-icons
    font-size: inherit

  &--yellow
    color: goldenrod
  &--red
    color: red
  &--green
    color: green
  &--clickable
    cursor: pointer
