.message-form
  &__controls
    display: flex
    align-items: flex-start
    justify-content: space-between
    margin: 0
    margin-top: 10px
    margin-bottom: 10px
  @media (max-width: 450px)
    &__controls
      flex-wrap: wrap

      & > *
        margin-bottom: 15px
