.tags
  display: inline-block
  vertical-align: middle
  margin-top: -10px
  margin-right: -15px

  &--short
    margin-right: -5px

  &__tag, &__add
    position: relative
    display: inline-block
    vertical-align: top
    padding: 0 10px
    margin-right: 15px
    margin-top: 10px
    border: 1px solid #a6a6a6
    border-radius: 4px
    line-height: 28px
    font-size: 12px
    color: #575757
    white-space: nowrap

  &__tag--short
    line-height: 20px
    font-size: 12px
    padding: 0 5px
    margin-right: 5px

  &__tag--selectable:hover
    cursor: pointer
    background-color: #a6a6a6
    color: white

  &__tag--current
    background-color: white
    border-color: #3095dd
    color: #3095dd
    &:hover
      background-color: #3095dd
      color: white

  &__add
    cursor: pointer
    padding: 0 10px
    border: 1px solid #3095dd
    font-size: 18px
    color: #3095dd

    &:hover
      background-color: #3095dd
      color: white

    &:active
      opacity: 0.6

  &__tag:hover &__delete
    display: block

  &__delete
    cursor: pointer
    display: none
    position: absolute
    right: 0
    top: 0
    transform: translate(50%, -50%)
    width: 18px
    height: 18px
    border: 1px solid #333
    border-radius: 50%
    color: #333
    background-color: white
    text-align: center
    line-height: 14px
    font-size: 14px
