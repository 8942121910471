.ticket-event
  display: block
  width: 100%
  margin-bottom: 10px

  &__content
    position: relative

  &__descr
    /*overflow: hidden*/

  &__person
    float: left
    margin-right: 10px
    vertical-align: top

  &__time
    font-size: 12px
    color: #8a8a8a
    margin-top: 5px

  &__other-manager
    position: absolute
    top: 50%
    left: -42px
    transform: translateY(-50%)
