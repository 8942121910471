.modal
  z-index: 300
  position: fixed
  left: 0
  top: 0
  right: 0
  bottom: 0
  background-color: rgba(black, 0.4)
  overflow-y: auto
  transform: translate3d(0, 0, 0)
  backface-visibility: hidden

  &__content
    width: 50%
    margin-top: 100px
    margin: 100px auto
    @media (max-width: 768px)
      width: 95%
      margin: 20px auto
    &--large
      width: 75%
