.dim-link
  cursor: pointer
  color: #5c5c5c

  &:hover
    opacity: 0.8

  &__text
    border-bottom: 1px dotted #5c5c5c
    font-size: 12px
