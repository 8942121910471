.table
  width: 100%
  border-spacing: 0

  th
    padding: 0 5px
    padding-bottom: 10px
    border-bottom: 1px solid #dadada
    color: black
    text-align: left
    font-size: 12px
    font-weight: 300
    text-transform: uppercase

  td
    vertical-align: middle
    padding: 5px
    height: 35px
    border-bottom: 1px solid #f9f9f9

  &--compact
    td, th
      border: 0
      height: auto

  @media (max-width: 1024px)
    &-wrapper
      overflow: auto
