.d-flex
  display: flex !important

.d-inline-flex
  display: inline-flex !important

.flex-fill
  flex: 1 1 auto !important

.flex-row
  flex-direction: row !important

.flex-column
  flex-direction: column !important

.flex-row-reverse
  flex-direction: row-reverse !important

.flex-column-reverse
  flex-direction: column-reverse !important

.flex-grow-0
  flex-grow: 0 !important

.flex-grow-1
  flex-grow: 1 !important

.flex-shrink-0
  flex-shrink: 0 !important

.flex-shrink-1
  flex-shrink: 1 !important

.flex-wrap
  flex-wrap: wrap !important

.flex-nowrap
  flex-wrap: nowrap !important

.flex-wrap-reverse
  flex-wrap: wrap-reverse !important

.justify-start
  justify-content: flex-start !important

.justify-end
  justify-content: flex-end !important

.justify-center
  justify-content: center !important

.justify-space-between
  justify-content: space-between !important

.justify-space-around
  justify-content: space-around !important

.align-start
  align-items: flex-start !important

.align-end
  align-items: flex-end !important

.align-center
  align-items: center !important

.align-baseline
  align-items: baseline !important

.align-stretch
  align-items: stretch !important

.align-content-start
  align-content: flex-start !important

.align-content-end
  align-content: flex-end !important

.align-content-center
  align-content: center !important

.align-content-space-between
  align-content: space-between !important

.align-content-space-around
  align-content: space-around !important

.align-content-stretch
  align-content: stretch !important

.align-self-auto
  align-self: auto !important

.align-self-start
  align-self: flex-start !important

.align-self-end
  align-self: flex-end !important

.align-self-center
  align-self: center !important

.align-self-baseline
  align-self: baseline !important

.align-self-stretch
  align-self: stretch !important