@import '../variables/colors.sass'

$margin-left: 44px

.message
  width: 75%
  margin-bottom: 20px
  position: relative
  @media (max-width: 768px)
    width: 100%

  &-form
    z-index: 1
    position: relative
    background: #ffffff

  &--controls
    padding-bottom: 24px
    .message__avatar
      bottom: 24px

  &--selected
    border: 1px solid

  &--clickable
    cursor: pointer

  &__person
    margin-left: $margin-left

  &__additional-info
    margin-left: $margin-left
    margin-top: 8px

  &__text
    margin-left: $margin-left
    border-radius: 10px
    padding: 20px
    background-color: #f6f6f6
    white-space: pre-wrap
    overflow-x: auto
    .attachment
      margin-top: 18px

    & a
      color: #24669f
      text-decoration: underline

    img
      max-width: 100%
    &--white
      background-color: #fff
      border: 1px solid lightgrey

    &--blue
      background-color: $color-blue-dim

    &--dark-blue
      background-color: #2682c3
      color: white
      & a
        color: white

    &--yellow
      background-color: $color-yellow-dim

    &--orange
      background-color: #ffe0b3

    &--chat-gpt
      background-color: #E6F3F3

    &--red
      background-color: #fce1e1
      color: #d32f2f

    &--grey
      background-color: #eee

  &__action
    cursor: pointer
    text-decoration: underline
    font-size: 80%

  &__actions
    position: absolute
    display: flex
    justify-content: flex-start
    padding-left: $margin-left
    bottom: 0
    left: 0

  &__avatar
    position: absolute
    left: 0
    bottom: 0

  &__time
    display: inline
    font-size: 12px
    color: #8a8a8a

  &__viewed
    display: inline
    color: green
    vertical-align: middle
    line-height: 1
    .icon
      vertical-align: top

  &__quote
    color: grey
    background-color: #f1f8ff
    font-style: italic
    padding: 5px
    margin-bottom: 5px
    border-left: solid
    border-radius: 0 5px 5px 0

  div + .message__quote
    margin-top: 5px
