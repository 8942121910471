@import '../variables/colors.sass'

.text-input
  width: 100%
  height: 40px
  border-radius: 4px
  &--inline
    min-width: 300px
    width: inherit
    margin: 10px
    @media (max-width: 480px)
      min-width: 240px

  &--small
    height: 32px
  &--short
    max-width: 75px
  &--error
    border: 1px solid $color-red
