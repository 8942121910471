.rating-event
  display: block
  margin-bottom: 20px

  &__message
    margin: 10px
    margin-left: 20px
    border-radius: 10px
    padding: 20px
    background-color: #eee

    &--bad
      background-color: rgba(251,220,220,0.8)
    &--good
      background-color: rgba(220,249,220,0.8)
