.page-transition
  &__curtain
    z-index: 200
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: white
    animation-name: curtain
    animation-duration: 10s

@keyframes curtain
  0%
    opacity: 0
  2%
    opacity: 0
  3%
    opacity: 0.2
  40%
    opacity: 0.2
  100%
    opacity: 0.8
