@import '../variables/colors.sass'

.person
  display: inline-block
  &--clickable
    cursor: pointer

  &__content
    display: flex
    align-items: center
    vertical-align: middle
    margin: 0 -5px

    &--right
      order: 1

  &--hide__avatar
    .person__name
      margin: 0px
    .person__content
      margin: 0px

  &__name
    margin: 0 5px
    font-weight: 500
    color: $color-emphasis
    &--white
      color: white

    &--dim
      font-weight: 300
      color: #5c5c5c

    &--big
      margin-left: 10px
      font-size: 22px
      font-weight: 300

  &__signature
    margin-left: 5px
    font-size: 12px

// new UI
.person .avatar
  margin: 0 5px
