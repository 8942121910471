
.rate-ticket
  & .rate-ticket__label
    color: #666
    margin-top: 10px
    margin-bottom: 10px

  & .rate-ticket__emotions
    & >*
      margin-right: 20px

  & .rate-ticket__controls
    margin: 10px 0
    text-align: right

  & .rate-ticket__answer_buttons
    margin: 10px 0
    text-align: center
