.dropdown-trigger
  display: inline-block

  &--visible
    position: relative
    z-index: 100

  &__trigger
    cursor: pointer
    border-bottom: 1px dotted
    white-space: nowrap

    &--no-underline
      border-bottom: 0
