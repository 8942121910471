@import './variables/colors.sass'

html
  background-color: #f7f8fa
  font-family: 'Roboto', sans-serif
  font-size: 14px
  font-weight: 300
  color: #333
  line-height: 1.5
  overflow-y: scroll

html, body
  padding: 0
  margin: 0
  height: 100%

*, *:before, *:after
  box-sizing: border-box

input, textarea
  color: $color-emphasis
  font-size: 14px
  font-family: 'Roboto', sans-serif
  font-weight: 300
  outline: 0
  background-color: white
  padding: 10px
  border: 1px solid #dadada
  &:focus
    border-color: #7f7f7f

h1, h2, h3
  color: $color-emphasis
  font-weight: 300
  margin: 0

h1
  font-size: 22px
  line-height: 1.3

h2
  font-size: 20px

a, .link
  cursor: pointer
  color: #1976d2
  text-decoration: none
  &:hover
    opacity: 0.8

.text
  &-gray
    color: #5c5c5c
  &-light-gray
    color: #8e8e8e
  &-green
    color: #8dc80e
  &-yellow
    color: $color-yellow-darken-4
  &-emphasis
    color: $color-emphasis
  &-red
    color: #ea3838
  &-cyan
    color: #35aa81
  &-emerald
    color: #07797a
  &-nowrap
    white-space: nowrap
  &-decoration-none
    text-decoration: none !important
  &-overflow-ellipsis
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis


  &-center
    text-align: center
  &-right
    text-align: right
  &-small-strong
    font-size: 11px
    font-weight: 500
  &-small
    font-size: 12px

hr
  border: 0
  margin: 0
  height: 1px
  background-color: #ededed

strong
  font-weight: 500

small
  font-size: 12px

.valign-middle
  vertical-align: middle

.nostyle
  text-decoration: inherit
  color: inherit

.cursor-pointer
  cursor: pointer
