@import '../variables/colors.sass'

.button
  display: inline-block
  cursor: pointer
  font: inherit
  font-size: 16px
  user-select: none
  border: 0
  border-radius: 4px
  padding: 0 16px
  height: 40px
  min-width: 71px
  color: white
  background-color: $color-blue
  white-space: nowrap

  &:hover
    opacity: 0.8

  &:active
    opacity: 0.7

  &:disabled
    opacity: 0.5
    background-color: $color-grey

  & *
    vertical-align: middle

  &--icon
    padding: 0 11px

  &__icon
    position: relative
    top: -2px
    font-size: 24px
    vertical-align: middle
    margin-left: 4px

  &--outline
    border: 1px solid $color-blue
    color: $color-blue
    background-color: transparent

    &:hover
      color: white
      background-color: $color-blue

  &--outline-secondary
    border: 1px solid $color-grey
    color: $color-grey
    background-color: transparent

    &:hover
      color: white
      background-color: $color-grey

  &--gray
    background-color: $color-grey

  &--green
    background-color: $color-green

  &--yellow
    background-color: $color-yellow

  &--surface-lighten
    background-color: #425870

  &--red
    background-color: $color-red

  &--small
    font-size: 14px
    height: 32px
    min-width: 57px
    padding: 0 12px
