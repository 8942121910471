@import '../variables/colors.sass'

.icon-button
  display: inline-block
  cursor: pointer
  user-select: none
  border: 0
  border-radius: 4px
  padding: 8px
  color: white
  background-color: $color-blue

  & .icon-button__icon .icon
    font-size: 16px

  &:hover
    opacity: 0.8

  &:active
    opacity: 0.7

  &:disabled
    opacity: 0.5
    background-color: $color-grey

  &--grey
    background-color: $color-grey
  &--green
    background-color: $color-green
  &--yellow
    background-color: $color-yellow
  &--red
    background-color: $color-red

  &--small
    height: 22px
