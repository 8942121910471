@import '../variables/colors.sass'

.radio
  display: inline-block
  cursor: pointer
  white-space: nowrap

  &:hover &__label
    opacity: 0.8

  &__input
    position: absolute
    left: -500px

  &__dummy
    display: inline-block
    position: relative
    vertical-align: middle
    width: 20px
    height: 20px
    border: 2px solid #C8CBD0
    border-radius: 50%
    &:hover
      border-color: #B4B7BD

  &__input:checked + &__dummy
    border-color: #498CE9

  &__input:checked + &__dummy:after
    content: ''
    position: absolute
    width: 8px
    height: 8px
    border-radius: 50%
    background-color: #498CE9
    top: calc(50% - 4px)
    left: calc(50% - 4px)

  &__tag_label
    display: inline-block
    vertical-align: middle
    margin-left: 7px

  &__tag_help
    color: $color-grey
    margin-left: 7px
    white-space: normal
    word-wrap: break-word
    @media (max-width: 430px)
      max-width: 300px
    @media (max-width: 320px)
      max-width: 230px
