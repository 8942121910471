.manager-call
  width: 75%
  border-radius: 10px
  background-color: orange
  padding-top: 5px
  padding-bottom: 10px
  margin-top: 15px
  margin-bottom: 15px

  &__heading
    padding-left: 10px
    font-weight: bold
    margin-bottom: 10px
  &__duration
    font-weight: 100
