.pagination
  font-size: 18px

  &-ellipsis
    display: inline-block
    vertical-align: middle
    padding: 6px 12px
    color: #2F3133
    font-size: 16px
    line-height: 24px
    font-weight: 500
    margin-right: 4px

  &-page
    display: inline-block
    vertical-align: middle
    color: #2F3133
    margin-right: 4px
    font-size: 16px
    line-height: 24px
    font-weight: 500
    a
      display: inline-block
      vertical-align: middle
      padding: 6px 12px
      color: #2F3133
      font-size: 16px
      line-height: 24px
      font-weight: 500

    &--current
      background: #EDEFF2
      display: inline-block
      vertical-align: middle
      font-weight: 500
      padding: 6px 12px
      border-radius: 4px
      margin-right: 4px

