$two-cols-side-width: 280px
$two-cols-side-margin-left: 20px

.two-cols
  display: flex
  align-items: flex-start
  @media (max-width: 768px)
    flex-direction: column

  &__main
    flex-grow: 1
    max-width: calc(100% - #{$two-cols-side-width} - #{$two-cols-side-margin-left})
    @media (max-width: 768px)
      max-width: 100%

  &__side
    width: $two-cols-side-width
    min-width: $two-cols-side-width
    margin-left: $two-cols-side-margin-left
    @media (max-width: 768px)
      width: 100%
      min-width: 300px
      margin-top: 20px
      margin-left: 0
