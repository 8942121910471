.card-header
  display: flex
  align-items: flex-end
  justify-content: space-between

  &__tabs
    position: relative
    white-space: nowrap
    @media (max-width: 640px)
      overflow-x: scroll
      overflow-y: hidden

    &:after
      content: ''
      position: absolute
      left: 0
      right: 0
      bottom: -8px
      height: 8px
      background-color: white

  &__tab
    display: inline-block
    margin-right: 10px
    padding: 0 40px
    line-height: 42px
    color: #333
    border-radius: 4px 4px 0 0
    background-color: #e4e5e5
    text-transform: uppercase

    &--current
      &:hover
        opacity: 1

    &:not(&--current)
      cursor: pointer

      &:hover
        opacity: 0.7

    &--current
      background-color: white
      box-shadow: 0 0 11px 2px rgba(31, 28, 22, 0.09)

  &__addon
    margin-bottom: 5px

  @media (max-width: 450px)
    &__tab
      padding: 0 20px
