.rating-icon
  display: inline

  & .rating-icon__icon
    vertical-align: middle
    &--positive
      color: #8dc80e

    &--neutral
      color: lightblue

    &--negative
      color: #ea3838

    &--uncolored
      color: #555

    &--clickable
      cursor: pointer
