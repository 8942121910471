@import '../variables/colors.sass'

.textarea
  width: 100%
  vertical-align: top
  min-height: 250px
  padding: 10px
  border-radius: 4px
  resize: vertical
  &--grey
    background-color: grey
  &--yellow
    background-color: $color-yellow-dim
  &--orange
    background-color: #ffe0b3
  &--green
    background-color: $color-green
  &--red
    background-color: $color-red
  &--small
    min-height: 100px
  &--error
    border: 1px solid $color-red
