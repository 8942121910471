.attachment
  width: 350px
  line-height: 1
  user-select: none

  & + .attachment
    margin-top: 16px

  &__name
    font-size: 12px
    font-weight: 500
    .material-icons
      font-size: 18px

  &__size
    font-size: 11px

  &__progress
    height: 2px
    bottom: 0
    left: 0
    background-color: #3095dd

  &__delete
    cursor: pointer
    position: relative
    display: inline-block
    vertical-align: top
    color: red
    font-size: 20px
    width: 20px
    height: 20px
    text-align: center
    line-height: 11px

    &:hover
      opacity: 0.6
