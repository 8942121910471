.opinion-text
  border-radius: 10px
  padding: 20px
  background-color: #eee
  white-space: pre-wrap
  overflow-x: auto

  &--bad
    background-color: #fce1e1
    color: #d32f2f

  &--good
    background-color: #e3f5ef
    color: #1b5e20
