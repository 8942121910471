.checkbox
  cursor: pointer

  &:hover &__label
    opacity: 0.8

  &__input
    position: absolute
    left: -500px

  &__dummy
    position: relative
    display: inline-block
    vertical-align: middle
    width: 20px
    height: 20px
    border: 2px solid #C8CBD0
    border-radius: 4px
    &:hover
      border-color: #B4B7BD

  &__input:checked + &__dummy
    border-color: #498CE9
    background-color: #498CE9

    &:after
      content: ""
      position: absolute
      height: 100%
      width: 100%
      top: 0
      left: 0
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.638 1.87378L4.63796 8.87378C4.37761 9.13413 3.9555 9.13413 3.69515 8.87378L0.361816 5.54044L1.30463 4.59764L4.16655 7.45957L10.6952 0.930969L11.638 1.87378Z' fill='white'/%3E%3C/svg%3E")
      background-position: center
      background-repeat: no-repeat
      background-size: 12px

  &__label
    display: inline-block
    vertical-align: middle
    margin-left: 10px
