.notification
  padding: 20px
  background-color: rgba(87, 143, 235, 1)
  text-align: center
  color: white
  font-weight: bold
  white-space: pre-line
  box-shadow: 0 0 11px 2px rgba(31, 28, 22, 0.09)

  @media (max-width: 450px)
    padding: 15px
