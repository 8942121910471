.avatar
  cursor: default
  display: inline-block
  width: 34px
  height: 34px
  min-width: 34px
  line-height: 34px
  border-radius: 50%
  color: white
  text-align: center
  font-size: 18px
  font-weight: 900

  &--green
    background-color: #00b300

  &--red
    background-color: #cc0000
  &--light-red
    background-color: #cc3300
  &--orange
    background-color: #cc6600
  &--light-orange
    background-color: #cc9900
  &--yellow
    background-color: #e6ca00
  &--light-blue
    background-color: #00cccc
  &--blue
    background-color: #0099cc
  &--dark-blue
    background-color: #0000ff
  &--purple
    background-color: #8000ff
  &--pink
    background-color: #b300b3
  &--brown
    background-color: #ff8000
  &--dark-brown
    background-color: #804000
