.tooltip
  display: inline-block
  position: relative

  &:hover &__bubble
    display: block
    z-index: 100

  &__bubble
    display: none
    position: absolute
    transform: translateY(-100%)
    top: -8px
    left: 0
    line-height: 30px
    padding: 0 10px
    box-shadow: 0 0 8px 1px rgba(31, 28, 22, 0.14)
    border-radius: 4px
    background-color: white
    font-size: 12px
    white-space: nowrap

    &--wrap
      white-space: normal
      word-wrap: break-word

    &--ticket-message
      width: 25vw
      max-height: 60vh
      overflow-y: hidden

  &__bubble-arrow
    position: absolute
    width: 100%
    left: 0
    height: 10px
    bottom: -10px
    overflow: hidden

    &:before
      content: ''
      position: absolute
      left: 25px
      top: -4px
      transform: rotate(45deg)
      width: 7px
      height: 7px
      box-shadow: 0 0 8px 1px rgba(31, 28, 22, 0.05)
      background-color: white
