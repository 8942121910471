.tabs
  border-bottom: 1px solid #C8CBD0
  @media (max-width: 480px)
    white-space: nowrap
    overflow-x: auto
    padding-bottom: 2px

  &__item:not(.tabs__tab--current .tabs__item)
    & *
      color: #8B8F94

  &__title
    font-weight: 500
    font-size: 18px

  &__caption
    font-weight: 400
    opacity: 0.8

  &__tab
    display: inline-block
    margin-right: 35px
    padding-bottom: 12px
    font-weight: 500
    font-size: 14px
    color: #2F3133

    &:not(&--current)
      cursor: pointer
      color: #8B8F94
      &:hover
        opacity: 0.8
      &:active
        opacity: 0.6

    &--current
      position: relative
      color: #2F3133

      &:before
        content: ''
        position: absolute
        left: 0
        right: 0
        bottom: -2px
        height: 3px
        background-color: #498CE9
        border-radius: 2px
