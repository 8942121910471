.icon-text
  display: inline-block
  white-space: nowrap

  &__icon
    display: inline-block
    position: relative
    vertical-align: middle
    line-height: 0
    margin-top: -2px

  &__text
    display: inline-block
