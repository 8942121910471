.appbar
  background-color: #2b3b4c
  color: white
  margin-bottom: 20px
  @media (max-width: 768px)
    padding-top: 8px
    padding-bottom: 8px

  &--with-search
    @media (max-width: 768px)
      .appbar__content
        flex-direction: column
        text-align: center

  &__content
    display: flex
    justify-content: space-between
    align-items: center

  &__menu-inner
    display: flex
    align-items: center

  &__menu-title
    font-size: 16px

  &__menu-icon
    font-size: 24px

  &__logo
    height: 48px

  &__logo-img
    display: inline-block
    vertical-align: middle
    margin-top: 9px

  &__controls
    display: inline-flex
    align-items: center

  &__help
    display: inline-block
    color: white
    &:hover
      opacity: 1
      .icon-text__text
        text-decoration: underline
