#emails-list
  tr
    &:hover
      background: #eee
    cursor: pointer

.is-spam
  font-size: 20px
  cursor: pointer
  padding: 5px

.spam-false:hover, .spam-true
  color: red

.spam-true:hover
  color: inherit

.email-card
  .email-header
    display: flex
    justify-content: space-between

    .is-spam
      font-size: 35px

  .email-body
    word-wrap: break-word
